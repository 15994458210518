<template>
    <div id="clinic-dashboard-products">
        <vs-row vs-type="flex">
            <div class="widget-container chart-widget" :style="{ width: '100%', minHeight: '280px', justifyContent: 'flex-start'}">
                <vs-row class="mb-2" vs-justify="space-between">
                    <div class="widget-title">Product Purchases</div>
                </vs-row>
                <div style="width: 100%; height: 100%;">
                    <template v-if="loadingCharts.productPurchases || isFetchingMainFilters">
                        <div 
                            class="skeleton skeleton-chart-columns" 
                            style="
                                --cols: 10;
                                --col-w: 30px;
                                --col-gap: 20px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                            "
                        ></div>
                    </template>
                    <template v-else>
                        <BarChart 
                            v-if="productPurchasesData.datasets.length"
                            :height="230"
                            :width="250"
                            :chartData="productPurchasesData"
                            :chartOptions="productPurchasesOptions"
                        />
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>
                </div>
            </div>
        </vs-row>
        
        <vs-row vs-type="grid" class="breakdown-grids">
            <div class="widget-container chart-widget" :style="{width: '100%', minHeight: '200px'}">
                <vs-row vs-justify="space-between">
                    <div class="widget-title" :style="{display: 'flex', width: '100%', justifyContent: 'space-between'}">Anti-wrinkle Spend Breakdown
                    
                        <QuestionTooltip>
                            <template slot="header">Anti-wrinkle Spend Breakdown</template>
                            <template slot="body">Breakdown by brand of anti-wrinkle products</template>
                        </QuestionTooltip>
                    </div>
                </vs-row>
                <div style="width: 100%; height: 100%;">
                    <template v-if="loadingCharts.productSpendBreakdowns || isFetchingMainFilters">
                        <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 300px;margin: 0 auto;"></div>
                        <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 250px;margin: 0 auto;"></div>
                        <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 350px;margin: 0 auto;"></div>
                        <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 200px;margin: 0 auto;"></div>
                        <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 320px;margin: 0 auto;"></div>
                    </template>
                    <template v-else>
                        <BarChart 
                            v-if="wrinkleSpendData.datasets.length"
                            :height="150"
                            :chartData="wrinkleSpendData"
                            :chartOptions="breakdownDataOptions"
                        />
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>
                </div>
            </div>
            <div class="widget-container chart-widget" :style="{width: '100%', minHeight: '200px'}">
                <vs-row vs-justify="space-between">
                    <div class="widget-title" :style="{display: 'flex', width: '100%', justifyContent: 'space-between'}">Filler Spend Breakdown
                    
                        <QuestionTooltip>
                            <template slot="header">Filler Spend Breakdown</template>
                            <template slot="body">Breakdown by brand of filler products</template>
                        </QuestionTooltip>
                    </div>
                    <div class="widget-options flex" style="justify-content: flex-end;">
                    </div>
                </vs-row>
                <div style="width: 100%; height: 100%;">
                    <template v-if="loadingCharts.productSpendBreakdowns || isFetchingMainFilters">
                        <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 300px;margin: 0 auto;"></div>
                        <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 250px;margin: 0 auto;"></div>
                        <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 350px;margin: 0 auto;"></div>
                        <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 200px;margin: 0 auto;"></div>
                        <div class="skeleton skeleton-line" style="--c-p: 10px; --c-w: 320px;margin: 0 auto;"></div>
                    </template>
                    <template v-else>
                        <BarChart 
                            v-if="fillerSpendData.datasets.length"
                            :height="150"
                            :chartData="fillerSpendData"
                            :chartOptions="breakdownDataOptions"
                        />
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>
                </div>
            </div>
        </vs-row>
        <vs-row vs-type="flex">
            <div class="widget-container chart-widget" :style="{width: '100%', minHeight: '330px'}">
                <vs-row vs-justify="space-between">
                    <div class="widget-title" :style="{display: 'flex', width: '100%', justifyContent: 'space-between'}">Product Dosage Trends
                    
                        <QuestionTooltip>
                            <template slot="header">Product Dosage Trends</template>
                            <template slot="body">
                                Number of treatments that you have completed broken down by area for a specific product, and average volume of product and average volume of product used per treatment compared with Fresh Clinics High Volume Clinics.
                                <br>Filler volumes displayed in ml, Toxin volumes displayed in units 
                            </template>
                        </QuestionTooltip>
                    </div>
                </vs-row>
                <vs-row vs-type="flex" class="insight-table-header">
                    <div class="dashboard-select full-label" style="width: 250px;">
                        <label class="select-label">Product</label>
                        <el-select 
                            popper-class="popper-select" 
                            v-model="selectedProduct"
                            placeholder=""
                            :disabled="loadingCharts.productDosageTrends || isFetchingMainFilters">
                            <el-option :key="index" :value="item.value" :label="item.text" v-for="item,index in productList" />
                        </el-select>
                    </div>
                    <!-- <vs-select
                        label="Age"
                        v-model="selectedAge"
                        >
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in ageList" />
                    </vs-select> -->
                    <!-- <vs-select
                        label="Gender"
                        v-model="selectedGender"
                        :disabled="loadingCharts.productDosageTrends || isFetchingMainFilters">
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in genderList" />
                    </vs-select> -->
                </vs-row>
                <div style="position: relative; width: 100%; height: 100%;">
                    <div 
                        class="skeleton skeleton-line" 
                        style="
                            --lines: 5;
                            --l-gap: 20px;
                            --c-w: 100%;
                            position: absolute;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        "
                        v-if="loadingCharts.productDosageTrends || isFetchingMainFilters"
                    ></div>
                    <template v-else>
                        <vs-table v-if="areas.length" noDataText="" class="insight-table-body" :data="areas" style="position: relative;  width: 100%; height: 100%;">
                            <template slot="thead">
                                <vs-th>Area</vs-th>
                                <vs-th></vs-th>
                                <vs-th class="th-wrapper" colspan="2">
                                    <vs-row vs-justify="center" class="pb-1">Total Treatments</vs-row>
                                    <vs-row>
                                        <div class="col2">Amount</div>
                                        <div class="col2">% of Total</div>
                                    </vs-row>
                                </vs-th>
                                <vs-th></vs-th>
                                <vs-th class="th-wrapper" colspan="4">
                                    <vs-row vs-justify="center" class="pb-1">Avg Treatment Volume</vs-row>
                                    <vs-row>
                                        <!-- <div class="col2">You</div>
                                        <div class="col2">High Volume Clinics</div> -->
                                        
                                        <div class="subheading col2">
                                            <div class="subvalue col2">You: Average</div>
                                            <div class="subvalue col2">You: Upper Range</div>
                                        </div>
                                        <div class="subheading col2">
                                            <div class="subvalue col2">High Volume Clinic: Average</div>
                                            <div class="subvalue col2">High Volume Clinic: Upper Range</div>
                                        </div>
                                    </vs-row>
                                </vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                                    <vs-td :data="data[indextr].area">
                                        {{data[indextr].area}}
                                    </vs-td>

                                    <vs-td></vs-td>

                                    <vs-td colspan="2" class="td-span text-center">
                                        <vs-row>
                                            <div class="col2">{{data[indextr].totalTreatmentsAmount}}</div>
                                            <div class="col2">{{data[indextr].totalTreatmentsPercentage}}%</div>
                                        </vs-row>
                                    </vs-td>

                                    <vs-td></vs-td>

                                    <vs-td colspan="4" class="td-span text-center">
                                        <vs-row>
                                            <div class="subheading col2">
                                                <div class="subvalue col2">{{data[indextr].clinicMinAmount}}</div>
                                                <div class="subvalue col2">{{data[indextr].clinicHighAmount}}</div>
                                            </div>
                                            <div class="subheading col2">
                                                <div class="subvalue col2">{{data[indextr].hvcMinAmount}}</div>
                                                <div class="subvalue col2">{{data[indextr].hvcMaxAmount}}</div>
                                            </div>
                                        </vs-row>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>
                </div>
            </div>
            
        </vs-row>
    </div>
</template>
<script>
import utils from '@/assets/utils'
import SearchAI from '../../components/insights/common/SearchAI'
import BarChart from '../../components/insights/charts/BarChart.vue'
import { mapActions } from "vuex";
import moment from "moment";
import { filter, uniqBy, map, find, sortBy } from "lodash";
import QuestionTooltip from '../../components/insights/common/QuestionTooltip'

export default {
    props: {
        selectedNurse: {
            type: String,
            default: ''
        },
        selectedClinic: {
            type: String,
            default: ''
        },
        isFetchingMainFilters: {
            type: Boolean,
            default: false
        },
        selectedDate: {
            type: Array,
            default: () => []
        }
    },
    components: {
        BarChart,
        SearchAI,
        QuestionTooltip
    },
    data() {
        return {
            loadingCharts: {
                productPurchases: false,
                productSpendBreakdowns: false,
                productDosageTrends: false
            },
            areas: [],
            selectedProduct: '',
            // selectedAge: 0,
            selectedGender: 'female',
            productList: [
                // { value: 0, text: "All" },
                // { value: 1, text: "Product A" },
                // { value: 2, text: "Product B" },
                // { value: 3, text: "Product C" },
                // { value: 4, text: "Product D" },
            ],
            // ageList: [
            //     { value: 0, text: "All" },
            //     { value: 1, text: "18 - 30" },
            //     { value: 2, text: "30 - 40" },
            //     { value: 3, text: "40 - 50" },
            //     { value: 4, text: "50 - 60" },
            //     { value: 5, text: "60 - 70" },
            //     { value: 6, text: "70+" },
            // ],
            genderList: [
                // { value: 0, text: "All" },
                { value: 'male', text: "Male" },
                { value: 'female', text: "Female" }
            ],
            productPurchasesData: {
                "labels": [],
                "datasets": []
            },
            productPurchasesOptions: {
                interaction: {
                    intersect: false,
                    axis: 'x',
                },
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyleWidth	: 14,
                            boxHeight: 10,
                            pointStyle: "circle",
                            usePointStyle: true,
                            padding: 20,
                            color: "#0E4333",
                            font: {
                                family: "InterMedium",
                                size: 12,
                            }
                        }
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltip: {
                        mode: 'index',
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function(context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function(context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            },
                            label: function(context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ';
                                }

                                const value = context.formattedValue
                                // check if context data is an object
                                if(typeof context.dataset.data[context.dataIndex] === 'object'){
                                    // context data is an array of objects
                                    label += "$" + context.dataset.data[context.dataIndex].y.toLocaleString()
                                } else {
                                    // context data is an array of numbers
                                    label += "$" + value
                                }
                                
                                return label;
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    }
                },
                scales: {
                    y: {
                        title: {
                            display: true,
                            text: '$ Purchased',
                            color: "#0E4333",
                            font: {
                                family: "InterMedium",
                                size: 12,
                            }
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return '$' + value.toLocaleString();
                            }
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4'
                        }
                    },
                    x: {
                        title: {
                            display: false,
                        },
                        grid : {
                            display : true,
                            color: '#EFF1F4'
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        }
                    }
                }
            },
            wrinkleSpendData: {
                labels: [],
                datasets: []
            },
            fillerSpendData: {
                labels: [],
                datasets: []
            },
            breakdownDataOptions: {
                indexAxis: 'y',
                interaction: {
                    intersect: false,
                    axis: 'y',
                },
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyleWidth	: 14,
                            boxHeight: 10,
                            pointStyle: "circle",
                            usePointStyle: true,
                            padding: 20,
                            color: "#0E4333",
                            font: {
                                family: "InterMedium",
                                size: 12,
                            }
                        }
                    },
                    title: {
                        display: true,
                        text: ''
                    },
                    tooltip: {
                        mode: 'index',
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function(context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function(context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            },
                            label: function(context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ';
                                }

                                const value = context.formattedValue
                                // check if context data is an object
                                if(typeof context.dataset.data[context.dataIndex] === 'object'){
                                    // context data is an array of objects
                                    label += "$" + context.dataset.data[context.dataIndex].x.toLocaleString()
                                } else {
                                    // context data is an array of numbers
                                    label += "$" + value
                                }
                                
                                return label;
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    },
                },
                scales: {
                    y: {
                        title: {
                            display: false,
                        },
                        // stacked: true,
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4'
                        }
                    },
                    x: {
                        title: {
                            display: false
                        },
                        // stacked: true,
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                            callback: function(value, index, ticks) {
                                return '$' + value.toLocaleString();
                            }
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4'
                        }
                    }
                }   
            }
        }
    },
    methods: {
        moment(date) {
            return moment(date);
        },
        ...mapActions("analytics", [
            "fetchClinicProductPurchases",
            "fetchClinicProductSpendBreakdowns",
            "fetchClinicProductFilter",
            "fetchClinicProductDosageTrends"
        ]),
        exportProductPurchases(chartData) {
            let csv = "\ufeff";
            let filename = `product-purchases`
            csv += "Date, Product Purchases ($)\n";

            chartData.labels.forEach((date, index) => {
                csv += `${date},${chartData.datasets[0].data[index]}\n`;
            });

            utils.exportToCSV(csv, `${filename}${moment().format('YYMMDDHHmm')}`)
        },
        exportWrinkleSpendData(chartData) {
            let csv = "\ufeff";
            let filename = `wrinkle-spend-breakdown`
            csv += "Treatment, Number of treatments\n";

            chartData.labels.forEach((label, index) => {
                csv += `${label},${chartData.datasets[0].data[index]}\n`;
            });

            utils.exportToCSV(csv, `${filename}${moment().format('YYMMDDHHmm')}`)
        },
        formatCurrency(value, round) {
            let val = (value/1).toFixed(round)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        fetchClinicProductPurchasesData() {
            // Reset Chart
            this.productPurchasesData = {
                "labels": [],
                "datasets": []
            }

            const payload = {
                clinic: [this.selectedClinic],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD"),
                demo: true
            }
            this.loadingCharts.productPurchases = true
            this.fetchClinicProductPurchases(payload).then((res) => {
                if(res && res.data)
                    if(res.data.length)
                        this.initializeProductPurchasesData(res.data)
                this.loadingCharts.productPurchases = false;
            })
            .catch((err) => {
                console.error("##ERROR: ", err.message);
                this.loadingCharts.productPurchases = false;
            });
        },
        initializeProductPurchasesData(responseData) {
            const data = sortBy(responseData, ['trunc_month'])
            const monthYear = data.map(({trunc_month}) => `${this.moment(trunc_month.toString(), 'YYYY-MM-DD').format('MMM YYYY')}`)
            const antiWrinkleData = data.map(({toxin_spend_amount }) => toxin_spend_amount )
            const fillerData = data.map(({filler_spend_amount }) => filler_spend_amount )
            
            const antiWrinklePurchases = antiWrinkleData.map((value, index) => {
                return {
                    x: fillerData[index],
                    y: value
                }
            })
            const fillerPurchases = antiWrinkleData.map((value, index) => {
                return {
                    x: value,
                    y: fillerData[index]
                }
            })

            this.productPurchasesData.labels = monthYear
            this.productPurchasesData.datasets = [
                {
                    "label": "Anti-Wrinkle",
                    "data": antiWrinklePurchases,
                    "backgroundColor": "#236E55"
                },
                {
                    "label": "Filler",
                    "data": fillerPurchases,
                    "backgroundColor": "#99E5DB"
                }
            ]
            
        },
        fetchClinicProductSpendBreakdownData() {
            // Reset Chart
            this.wrinkleSpendData = {
                labels: [],
                datasets: []
            }
            this.fillerSpendData = {
                labels: [],
                datasets: []
            }

            const payload = {
                clinic: [this.selectedClinic],
                demo: true
            }
            this.loadingCharts.productSpendBreakdowns = true
            this.fetchClinicProductSpendBreakdowns(payload).then((res) => {
                if(res && res.data)
                    if(res.data.length)
                        this.initializeProductSpendBreakdownData(res.data)
                this.loadingCharts.productSpendBreakdowns = false;
            })
            .catch((err) => {
                console.error("##ERROR: ", err.message);
                this.loadingCharts.productSpendBreakdowns = false;
            });
        },
        initializeProductSpendBreakdownData(data) {

            const antiWrinkleData = filter(data, function(o){ return o.product_type == 'Anti-wrinkle' });
            const fillerData = filter(data, function(o){ return o.product_type == 'Filler' });
            const antiWrinkleUniq = uniqBy(antiWrinkleData, 'product_family')
            const antiWrinkleLabels = map(antiWrinkleUniq, 'product_family')
            const fillerUniq = uniqBy(fillerData, 'product_family')
            const fillerLabels = map(fillerUniq, 'product_family')

            const antiWrinkleYTDValues = map(antiWrinkleLabels, function(labelValue) {
                const val = find(antiWrinkleData, function(o) { return o.product_family == labelValue && o.data_type == "YTD"; });
                return (val) ? val.total_spend_amount : 0
            })
            const antiWrinkleLMValues = map(antiWrinkleLabels, function(labelValue) {
                const val = find(antiWrinkleData, function(o) { return o.product_family == labelValue && o.data_type == "Last Month"; });
                return (val) ? val.total_spend_amount : 0
            })
            const fillerYTDValues = map(fillerLabels, function(labelValue) {
                const val = find(fillerData, function(o) { return o.product_family == labelValue && o.data_type == "YTD"; });
                return (val) ? val.total_spend_amount : 0
            })
            const fillerLMValues = map(fillerLabels, function(labelValue) {
                const val = find(fillerData, function(o) { return o.product_family == labelValue && o.data_type == "Last Month"; });
                return (val) ? val.total_spend_amount : 0
            })

            const antiWrinkleYTD = antiWrinkleYTDValues.map((value, index) => {
                return {
                    x: value,
                    y: antiWrinkleLMValues[index]
                }
            })

            const antiWrinkleLM = antiWrinkleYTDValues.map((value, index) => {
                return {
                    x: antiWrinkleLMValues[index],
                    y: value
                }
            })
            
            this.wrinkleSpendData.labels = antiWrinkleLabels
            this.wrinkleSpendData.datasets = [
                {
                    type: 'bar',
                    label: 'Last Month',
                    data: antiWrinkleLM,
                    backgroundColor: '#236E55'
                },
                {
                    type: 'bar',
                    label: 'Total Spend YTD',
                    data: antiWrinkleYTD,
                    backgroundColor: '#99E5DB'
                }
            ]
            
            const fillerYTD = fillerYTDValues.map((value, index) => {
                return {
                    x: value,
                    y: fillerLMValues[index]
                }
            })

            const fillerLM = fillerYTDValues.map((value, index) => {
                return {
                    x: fillerLMValues[index],
                    y: value
                }
            })

            this.fillerSpendData.labels = fillerLabels
            this.fillerSpendData.datasets = [
            {
                    type: 'bar',
                    label: 'Last Month',
                    data: fillerLM,
                    backgroundColor: '#236E55'
                },
                {
                    type: 'bar',
                    label: 'Total Spend YTD',
                    data: fillerYTD,
                    backgroundColor: '#99E5DB'
                }
            ]
        },
        fetchClinicFilterData() {
            const payload = {
                clinic: [this.selectedClinic],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD"),
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                demo: true
            }
            this.loadingCharts.productDosageTrends = true
            this.fetchClinicProductFilter(payload).then((res) => {
                if(res && res.data)
                    if(res.data.length)
                        this.initializeProductFilterData(res.data)
            }).catch((err) => {
                console.error("##ERROR: ", err.message);
                this.loadingCharts.productDosageTrends = false;
            });
        },
        initializeProductFilterData(data){
            let productList = []
            
            const sortedProductData= sortBy(data, [function(d) { return d.name; }]);

            sortedProductData.map((d) => {
                productList.push({
                    value: d.id,
                    text: d.name
                })
            })
            
            this.productList = productList
            if (this.productList.length > 0) {
                this.selectedProduct = productList[0].value
            }
        },
        fetchClinicProductDosageTrendsData() {
            // Reset Chart
            this.areas = []

            const payload = {
                clinic: [this.selectedClinic],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD"),
                product: [this.selectedProduct],
                // gender: this.selectedGender,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                demo: true
            }

            this.loadingCharts.productDosageTrends = true
            this.fetchClinicProductDosageTrends(payload).then((res) => {
                if(res && res.data)
                    if(res.data.length)
                        this.initializeProductDosageTrendsData(res.data)
                this.loadingCharts.productDosageTrends = false;
            })
            .catch((err) => {
                console.error("##ERROR: ", err.message);
                this.loadingCharts.productDosageTrends = false;
            });
        },
        initializeProductDosageTrendsData(data) {
            
            const areaData = data.map((d) => {
                const roundCount = d.treatment_type == 'Toxin' ? 0 : 1
                return {
                    area: d.treatment_area,
                    totalTreatmentsAmount: d.treatment_count,
                    totalTreatmentsPercentage: this.roundToDecimalPlaces(d.treatment_count_percentage, 2),
                    // avgTreatmentVolume: this.formatCurrency(d.avg_treatment_amount, roundCount),                    
                    // avgTreatmentVolumeTop: (d.top_min_treatment_amount || d.top_max_treatment_amount) ? this.formatCurrency(d.top_min_treatment_amount, roundCount) + ' - ' + this.formatCurrency(d.top_max_treatment_amount, roundCount) : null
                    clinicMinAmount: (d.clinic_min_amount) ? this.formatCurrency(d.clinic_min_amount, roundCount) : 0,
                    clinicHighAmount: (d.clinic_high_amount) ? this.formatCurrency(d.clinic_high_amount, roundCount) : 0,
                    hvcMinAmount: (d.hvc_min_amount) ? this.formatCurrency(d.hvc_min_amount, roundCount) : 0,
                    hvcMaxAmount: (d.hvc_max_amount) ? this.formatCurrency(d.hvc_max_amount, roundCount) : 0,
                }
            })
            
            const sortedAreaData = sortBy(areaData, [function(o) { return o.area; }]);

            this.areas = sortedAreaData
        },
        roundToDecimalPlaces(num, decimalPlaces) {
            const multiplier = 10 ** decimalPlaces;
            return Math.round(num * multiplier) / multiplier;
        },
        async fetchClinicPerformance(){
            if(this.selectedClinic) {
                this.fetchClinicProductPurchasesData()
                this.fetchClinicProductSpendBreakdownData()
                this.fetchClinicFilterData()
                this.fetchClinicProductDosageTrendsData()
            }            
        }
    },
    watch: {
        selectedNurse: {
            handler(newVal) {
                this.fetchClinicPerformance()
            },
            immediate: true,
        },
        selectedClinic: {
            handler(newVal) {
                this.fetchClinicPerformance()
            },
            immediate: true,
        },
        selectedDate: {
            handler(newVal) {
                if(newVal.length) {
                    this.fetchClinicPerformance()
                }
            },
            immediate: true,
        },
        selectedProduct: {
            handler(newVal) {
                if(newVal.length) {
                    this.fetchClinicProductDosageTrendsData()
                }
            },
            immediate: true,
        },
        selectedGender: {
            handler(newVal) {
                if(newVal.length) {
                    this.fetchClinicProductDosageTrendsData()
                }
            },
            immediate: true,
        }
    },
}
</script>
<style>
.breakdown-grids {
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 12px;
    margin-bottom: 12px;
}
.insight-table-body .th-wrapper {
    padding: 10px 0 !important;
}
.insight-table-body .th-wrapper .vs-table-text {
    width: 100%;
    display: block;
    text-align: center;
}
.insight-table-body .td-span {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.insight-table-body .th-wrapper .col2,
.insight-table-body .td-span .col2 {
    width: 50%;
    float: left;
}
.insight-table-body .th-wrapper .col4,
.insight-table-body .td-span .col4 {
    width: 25%;
    float: left;
}
.insight-table-body .th-wrapper .subheading.col2 {
    align-items: center;
}
.insight-table-body .subheading.col2 {
    display: flex;
    justify-content: center;
}
.insight-table-body .subvalue.col2 {
    max-width: 150px;
}
.insight-table-body th .vs-table-text {
    color: #37695B;
    font-family: 'InterSemiBold';
    font-size: 12px;
}
.insight-table-body .vs-con-tbody {
    border: none;
    background: none;
}
.insight-table-body thead tr {
    background-color: rgba(132, 205, 184, 0.1);
    border-top: 1px solid #C9DED8;
}
.insight-table-header {
    background-color: rgba(132, 205, 184, 0.1);
    border-radius: 15px 15px 0 0;
    padding: 10px;
    gap: 10px;
    margin-top: 10px;
}
.insight-table-header .el-select .el-input.is-focus .el-input__inner,
.insight-table-header .el-select .el-input__inner:focus,
.insight-table-header .el-select .el-input__inner:hover {
    border-color: #DCDFE6;
}
.insight-table-header .dashboard-select,
.insight-table-header .con-select {
    display: flex;
    gap: 10px;
    align-items: center;
}
.insight-table-header .vs-select--label,
.insight-table-header .select-label {
    color: #37695B;
    font-family: 'InterSemiBold';
    font-size: 12px;
}
.el-select-dropdown.popper-select .selected {
    color: #37695B;
    font-family: 'InterSemiBold';
}

.el-select-dropdown.popper-select .el-select-dropdown__item {
    font-family: 'Inter';
}

@media only screen and (max-width: 991px) {
    .breakdown-grids .chart-widget {
        min-height: auto !important;
    }
}

@media only screen and (max-width: 1068px) {
    .breakdown-grids {
        display: block !important;
    }
}
</style>